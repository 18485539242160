import { Link } from '@/components/Link';
import { useStableProps } from '@/hooks/useStable';
import { mergeProps } from '@/utils/merge';
import { StandaloneSubscriptionDetails, StandaloneSubscriptionDetailsProps } from 'base/components/SubscriptionDetails';
import { StandaloneSubscriptionDetailsDigital } from 'base/components/SubscriptionDetails/Digital';
import { useMemo } from 'react';

export const FeminaStandaloneSubscriptionDetails: typeof StandaloneSubscriptionDetails = (props) => {
  const memoizedSubscriptionDetails = useMemo(
    () => (
      <StandaloneSubscriptionDetailsDigital
        headline="Femina plus"
        preSubscriptionDescription="Du har ingen aktiv prenumeration"
        subscriptionDescription="Tillgång till alla digitala artiklar på femina.se"
        contactInfo={
          <>
            <div className="w-full">Om du har några frågor, kontakta oss på </div>
            <Link
              content="femina@kundtjanst.se"
              href="mailto:femina@kundtjanst.se"
              options={{ className: 'text-summer-red-700 underline' }}
            />
          </>
        }
        history={{
          headline: 'Din betalningshistorik',
        }}
        cancelSubscriptionButton={{
          content: 'Avsluta prenumeration',
        }}
        paymentHistoryButton={{
          content: 'Betalningshistorik',
        }}
        confirmationDialog={{
          headline: 'Här kan du avsluta din prenumeration av Femina Plus',
          content:
            'Är du helt säker på att du vill avsluta din prenumeration, och gå miste om allt unikt innehåll bara för dig som Plus prenumerant?',
          primaryButton: 'Jag vill avsluta min prenumeration',
        }}
      />
    ),
    [],
  );

  const memoizedPrintSubscriptionContent = useMemo(
    () => (
      <>
        Dina printprenumerationer hanteras på prenumerera.se,
        <br className="hidden md:block" />
        <Link
          href="https://prenumerera.se/kundservice/"
          content="klicka här för att komma dit"
          options={{ className: 'text-summer-red-700 underline' }}
        />
      </>
    ),
    [],
  );

  const stableProps: StandaloneSubscriptionDetailsProps = useStableProps({
    digitalSubscriptionAccordionProps: {
      title: (
        <>
          Hantera din <strong>digitala prenumeration</strong>
        </>
      ),
      content: memoizedSubscriptionDetails,
    },
    printSubscriptionAccordionProps: {
      title: (
        <>
          Hantera din <strong>print-prenumeration</strong>
        </>
      ),
      content: memoizedPrintSubscriptionContent,
    },
  });
  return <StandaloneSubscriptionDetails {...mergeProps(stableProps, props)} />;
};
